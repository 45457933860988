// uiSettings 模块
export default {
  namespaced: true,
  state: {
    isDarkMode: JSON.parse(localStorage.getItem('isDarkMode') ?? 'false'), // 从 localStorage 获取初始化值
  },
  mutations: {
    toggleDarkMode(state, isDarkMode) {
      state.isDarkMode = isDarkMode;
      const theme = isDarkMode ? 'dark' : 'light';

      // 更新 DOM 和保存到 localStorage
      document.documentElement.setAttribute('data-theme', theme);
      document.documentElement.classList.toggle('dark', isDarkMode);

      // 保存到 localStorage
      const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
      userInfo.isDarkMode = isDarkMode;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
  },
  actions: {
    // 初始化主题
    initializeTheme({ commit }, isDarkMode) {
      commit('toggleDarkMode', isDarkMode);
    },
    toggleDarkMode({ commit, state }) {
      commit('toggleDarkMode', !state.isDarkMode); // 切换主题
    },
  },
};
