// router/queryRoutes.js
export default [
    {
      path: 'Progress',
      component: () => import('@/views/query/ProgressQuery.vue'),
      meta: { title: '进度查询 - 神话网游小铺', breadcrumbs: ['进度查询']}
      },
    // {
    //   path: 'games',
    //   component: () => import('@/views/query/jx3apiQuery.vue'),
    //   meta: { title: '游戏查询 - 神话网游小铺', breadcrumbs: ['查询中心', '游戏查询'],keepAlive: true }
    //   }
  ];
  