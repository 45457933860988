// frontend/src/utils/cacheUtils.js

  /**
    * 按 type_name 分割 items 缓存
    * @param {Array} items - 缓存数据（items 数组）
    * @returns {Object} - 按 type_name 分组后的对象
  */
  export function splitItemsByTypeName(items) {
    if (!Array.isArray(items)) {
      console.error("splitItemsByTypeName: 数据格式无效，items 应为数组");
      return {};
    }
  
    return items.reduce((acc, item) => {
      const key = item.type_name || "未分类";
      if (!acc[key]) acc[key] = [];
      acc[key].push(item);
      return acc;
    }, {});
  }
  
  /**
   * 按 region 分割 servers 缓存
   * @param {Array} servers - 缓存数据（servers 数组）
   * @returns {Object} - 按 region 分组后的对象
   */
  export function splitServersByRegion(servers) {
    if (!Array.isArray(servers)) {
      console.error("splitServersByRegion: 数据格式无效，servers 应为数组");
      return {};
    }
  
    return servers.reduce((acc, server) => {
      const key = server.region || "未分类";
      if (!acc[key]) acc[key] = [];
      acc[key].push(server);
      return acc;
    }, {});
  }
  
  /**
   * 按项目状态分割 projects 缓存
   * @param {Array} projects - 缓存数据（projects 数组）
   * @returns {Object} - 按项目状态分组后的对象
   */
  export function splitProjectsByStatus(projects) {
    if (!Array.isArray(projects)) {
      console.error("splitProjectsByStatus: 数据格式无效，projects 应为数组");
      return {};
    }
  
    return projects.reduce((acc, project) => {
      const key = project.status || "未分类";
      if (!acc[key]) acc[key] = [];
      acc[key].push(project);
      return acc;
    }, {});
  }
  
  /**
 * 按 config_name 分割 systems 缓存
 * @param {Array} systems - 缓存数据（systems 数组）
 * @returns {Object} - 按 config_name 分组后的对象
 */
export function splitSystemsByConfigName(systems) {
  if (!Array.isArray(systems)) {
    console.error("splitSystemsByConfigName: 数据格式无效，systems 应为数组");
    return {};
  }

  return systems.reduce((acc, system) => {
    const key = system.config_name || "未分类";
    if (!acc[key]) acc[key] = [];
    acc[key].push(system);
    return acc;
  }, {});
}