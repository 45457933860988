// frontend\src\store\modules\dataCache.js
import { ElMessage } from 'element-plus';  // 引入 element-plus 的消息组件
import axios from 'axios';
import { splitItemsByTypeName, splitServersByRegion, splitProjectsByStatus, splitSystemsByConfigName } from '@/utils/cacheUtils';

const getters = {
  // 按 type_name 分组 items
  groupedItemsByType(state) {
    return splitItemsByTypeName(state.items);
  },

  // 按 region 分组 servers
  groupedServersByRegion(state) {
    return splitServersByRegion(state.servers);
  },

  // 按 status 分组 projects
  groupedProjectsByStatus(state) {
    return splitProjectsByStatus(state.projects);
  },
  // 按 config_name 分组 systems
  groupedSystemsByConfigName(state) {
    return splitSystemsByConfigName(state.systemConfig);
  },
};

const CACHE_KEYS = {
  ITEMS: { key: 'cache_items', ttl: 3600 * 1000 }, // 1小时
  PROJECTS: { key: 'cache_projects', ttl: 3600 * 1000 },
  SERVERS: { key: 'cache_servers', ttl: 3600 * 1000 },
  SYSTEM_CONFIG: { key: 'cache_system', ttl: 3600 * 1000 },
};

const CacheManager = {
  setCache(key, data) {
    localStorage.setItem(key, JSON.stringify({ data }));
  },
  getCache(key) {
    const cache = localStorage.getItem(key);
    if (!cache) return null;

    const { data } = JSON.parse(cache);
    return data; // 直接返回 data
  },
  clearCache(key) {
    localStorage.removeItem(key);
  },
  clearAllCache() {
    Object.values(CACHE_KEYS).forEach(({ key }) => localStorage.removeItem(key));
  },
};



const state = {
  items: [],
  projects: [],
  servers: [],
  systemConfig: [],
  isDataLoaded: false, // 标志是否已加载全局数据
};

const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_SERVERS(state, servers) {
    state.servers = servers;
  },
  SET_SYSTEM_CONFIG(state, config) {
    state.systemConfig = config;
  },
  SET_DATA_LOADED(state, loaded) {
    state.isDataLoaded = loaded;
  },
};

const actions = {
  async loadItems({ commit }, { forceReload = false } = {}) {
    const { key, ttl } = CACHE_KEYS.ITEMS;
    const cachedData = CacheManager.getCache(key);

    // 如果需要强制加载或缓存不存在，则重新加载
    if (forceReload || !cachedData) {
      try {
        const response = await axios.get('/api/item');
        CacheManager.setCache(key, response.data, ttl);
        commit('SET_ITEMS', response.data);
        return response.data;
      } catch (error) {
        console.error('Error loading items:', error);
        throw error;
      }
    }

    commit('SET_ITEMS', cachedData);
    return cachedData;
  },

  async loadProjects({ commit }, { forceReload = false } = {}) {
    const { key, ttl } = CACHE_KEYS.PROJECTS;
    const cachedData = CacheManager.getCache(key);

    if (forceReload || !cachedData) {
      try {
        const response = await axios.get('/api/projects');
        CacheManager.setCache(key, response.data, ttl);
        commit('SET_PROJECTS', response.data);
        return response.data;
      } catch (error) {
        console.error('Error loading projects:', error);
        throw error;
      }
    }

    commit('SET_PROJECTS', cachedData);
    return cachedData;
  },

  async loadServers({ commit }, { forceReload = false } = {}) {
    const { key, ttl } = CACHE_KEYS.SERVERS;
    const cachedData = CacheManager.getCache(key);

    if (forceReload || !cachedData) {
      try {
        const response = await axios.get('/api/servers');
        CacheManager.setCache(key, response.data, ttl);
        commit('SET_SERVERS', response.data);
        return response.data;
      } catch (error) {
        console.error('Error loading servers:', error);
        throw error;
      }
    }

    commit('SET_SERVERS', cachedData);
    return cachedData;
  },

  async loadSystemConfig({ commit }, { forceReload = false } = {}) {
    const { key, ttl } = CACHE_KEYS.SYSTEM_CONFIG;
    const cachedData = CacheManager.getCache(key);

    if (forceReload || !cachedData) {
      try {
        const response = await axios.get('/api/system/');
        CacheManager.setCache(key, response.data, ttl);
        commit('SET_SYSTEM_CONFIG', response.data);
        return response.data;
      } catch (error) {
        console.error('Error loading system config:', error);
        throw error;
      }
    }

    commit('SET_SYSTEM_CONFIG', cachedData);
    return cachedData;
  },

  async loadGlobalData({ dispatch, commit }) {
    try {
      await Promise.all([dispatch('loadItems'), dispatch('loadProjects'), dispatch('loadServers'), dispatch('loadSystemConfig')]);
      commit('SET_DATA_LOADED', true); // 标记数据已加载
    } catch (error) {
      console.error('Error loading global data:', error);
      throw error;
    }
  },

  async isConfigCacheValid({ dispatch, commit }) {
    const cacheKey = "Update_Timestamp";
    const cachedData = CacheManager.getCache(cacheKey); // 获取缓存数据
    console.log("Cached timestamp:", cachedData);
  
    try {
      const response = await axios.get("/api/temp/update-timestamp");
      const serverTimestamp = response.data.Update_Timestamp;
      console.log("Server timestamp:", serverTimestamp);
  
      // 比对缓存的 data 和服务器的时间戳
      if (cachedData === serverTimestamp) {
        return true; // 如果相同，缓存有效
      }
  
      // 如果时间戳不同，更新缓存并重新加载数据
      CacheManager.setCache(cacheKey, serverTimestamp); // 更新缓存
      ElMessage({
        message: '检测到新的配置数据，正在重新加载数据...',
        type: 'warning',
      });
  
      // 强制刷新数据
      const [items, projects, servers, systemConfig] = await Promise.all([
        dispatch('loadItems', { forceReload: true }),
        dispatch('loadProjects', { forceReload: true }),
        dispatch('loadServers', { forceReload: true }),
        dispatch('loadSystemConfig', { forceReload: true }),
      ]);
  
      // 更新缓存
      CacheManager.setCache(CACHE_KEYS.ITEMS.key, items);
      CacheManager.setCache(CACHE_KEYS.PROJECTS.key, projects);
      CacheManager.setCache(CACHE_KEYS.SERVERS.key, servers);
      CacheManager.setCache(CACHE_KEYS.SYSTEM_CONFIG.key, systemConfig);
  
      ElMessage({
        message: '最新配置数据更新完成！',
        type: 'success',
      });
  
      return false; // 缓存无效，需要重新加载数据
    } catch (error) {
      console.error("Error fetching config update timestamp:", error);
      return false; // API 调用失败，缓存无效
    }
  },
  

  clearAllCache({ commit }) {
    CacheManager.clearAllCache();
    commit('SET_ITEMS', []);
    commit('SET_PROJECTS', []);
    commit('SET_SERVERS', []);
    commit('SET_SYSTEM_CONFIG', []);
    commit('SET_DATA_LOADED', false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters, // 组件中通过 mapGetters 使用这些 Getter
};
