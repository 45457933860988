//frontend\src\router\modules\appRoutes.js
export default [
    {
    path: 'calendar',
    component: () => import('@/views/application/Calendar.vue'),
    meta: { title: '排班系统 - 神话网游小铺', breadcrumbs: ['排班系统'],keepAlive: true }
    },
    {
    path: 'todo',
    component: () => import('@/views/application/TodoList.vue'),
    meta: { title: '任务系统 - 神话网游小铺', breadcrumbs: ['任务系统'],keepAlive: true }
    },
    {
    path: 'chat',
    component: () => import('@/views/application/Chat.vue'),
    meta: { title: '聊天系统 - 神话网游小铺', breadcrumbs: ['聊天系统'],keepAlive: true }
    },
    {
    path: 'record',
    component: () => import('@/views/application/ClockInRecord.vue'),
    meta: { title: '打卡记录 - 神话网游小铺', breadcrumbs: ['打卡记录'],keepAlive: true }
    },
  ];