export const Cache = {
    // 设置缓存
    set(key, value, ttl = 3600 * 1000) { // 默认缓存一小时
      const expiry = Date.now() + ttl;
      const cacheData = { data: value, expiry };
      localStorage.setItem(key, JSON.stringify(cacheData));
    },
  
    // 获取缓存
    get(key) {
      const cache = localStorage.getItem(key);
      if (!cache) return null;
  
      const { data, expiry } = JSON.parse(cache);
      if (Date.now() > expiry) {
        localStorage.removeItem(key); // 缓存过期，清理
        return null;
      }
  
      return data;
    },
  
    // 清除缓存
    clear(key) {
      localStorage.removeItem(key);
    },
  };
  