// frontend\src\store\modules\user.js
import { Cache } from '@/utils/storage';

const state = {
  // 初始化 userInfo，从 localStorage 中加载
  userInfo: (() => {
    try {
      return JSON.parse(localStorage.getItem('userInfo')) || null;
    } catch {
      localStorage.removeItem('userInfo');
      return null;
    }
  })(),
};

const mutations = {
  // 设置用户信息
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  },
  // 清除用户信息
  clearUserInfo(state) {
    state.userInfo = null;
    localStorage.removeItem('userInfo');
  },
};

const actions = {
  // 用户登录后设置信息
  async setUserInfo({ commit, dispatch }, userInfo) {
    commit('setUserInfo', userInfo);

    // 登录成功后加载全局数据
    await dispatch('dataCache/loadGlobalData', null, { root: true });

    // 初始化主题（假设 isDarkMode 存储在 userInfo 中）
    const isDarkMode = userInfo?.isDarkMode || false;
    dispatch('theme/initializeTheme', isDarkMode, { root: true }); // 调用主题模块的初始化方法
  },

  // 异步清除用户信息
  clearUserInfo({ commit }) {
    commit('clearUserInfo');
    Cache.clear('user_info'); // 清理相关缓存数据
  },

  // 加载用户信息（页面刷新时调用）
  loadUserInfo({ commit, getters }) {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo && getters.isLoggedIn) {
        commit('setUserInfo', userInfo);
      } else {
        commit('clearUserInfo'); // 信息过期或无效
      }
    } catch {
      localStorage.removeItem('userInfo');
      commit('clearUserInfo');
    }
  },
};

const getters = {
  // 判断用户是否已登录
  isLoggedIn(state) {
    if (!state.userInfo) {
      return false; // 用户信息为空，未登录
    }
    const currentTime = Date.now();
    const { expirationTime = 0, rememberMe = false } = state.userInfo;
    // 如果 rememberMe 为 true 或未过期，则认为已登录
    return rememberMe || currentTime < expirationTime;
  },

  // 获取用户昵称
  nickname(state) {
    return state.userInfo?.nickname || '不知名用户';
  },

  // 获取用户角色
  role(state) {
    return state.userInfo?.role || 'user';
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
