// frontend\src\store\modules\breadcrumbs.js
export default {
  namespaced: true,  // 启用命名空间
  state: {
    breadcrumbs: [],  // 存储面包屑的数组
  },
  mutations: {
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
  },
};
