// router/serviceRouters.js
export default [
    {
    path: 'service/problems',
    component: () => import('@/views/service/ProblemMarks.vue'),
    meta: { title: '问题管理 - 神话网游小铺', breadcrumbs: ['问题管理'], keepAlive: true }
    },
    {
      path: 'service/problems/add',
      component: () => import('@/views/service/AddProblemModal.vue'),
      meta: { title: '问题登记 - 神话网游小铺', breadcrumbs: ['问题登记'], keepAlive: true }
      },
    {
    path: 'service/request',
    component: () => import('@/views/service/CustomerRequest.vue'),
    meta: { title: '客户请求 - 神话网游小铺', breadcrumbs: ['客户请求'], keepAlive: true }
    },
    {
      path: 'service/feedback',
      component: () => import('@/views/service/CustomerFeedback.vue'),
      meta: { title: '通知系统 - 神话网游小铺', breadcrumbs: ['通知系统'], keepAlive: true }
    },

  ];
  

  