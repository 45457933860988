// frontend\src\router\index.js
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; 
import orderRoutes from './modules/orderRoutes';
import customerRoutes from './modules/customerRoutes';
import settingsRoutes from './modules/settingsRoutes';
import queryRoutes from './modules/queryRoutes';
import appRoutes from './modules/appRoutes';
import serviceRouters from './modules/serviceRouters';

// 路径常量
const ROUTE_PREFIXES = {
  HOME: '/home',
};

// 懒加载其他组件
const LoginForm = () => import('@/components/auth/LoginForm.vue');
const RegisterForm = () => import('@/components/auth/RegisterForm.vue');
const ResetForm = () => import('@/components/auth/ResetForm.vue');
const MainLayout = () => import('@/components/layout/MainLayout.vue');
const NotFound = () => import('@/NotFound.vue');
const HomePage = () => import('@/HomePage.vue');
const UpdateLog = () => import('@/views/application/UpdateLog.vue');

// 路由配置
const routes = [
  { path: '/', component: LoginForm, meta: { title: '登录 - 神话网游小铺' } },
  { path: '/register', component: RegisterForm, meta: { title: '注册 - 神话网游小铺' } },
  { path: '/reset-password', component: ResetForm, meta: { title: '重置密码 - 神话网游小铺' } },
  {
    path: ROUTE_PREFIXES.HOME,
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '', component: HomePage, meta: { title: '首页 - 神话网游小铺', breadcrumbs: ['欢迎回来'] } },
      ...orderRoutes,
      ...customerRoutes,
      ...queryRoutes,
      ...settingsRoutes,
      ...appRoutes,
      ...serviceRouters,
      { path: 'update-log', component: UpdateLog, meta: { title: '更新日志 - 神话网游小铺' } },
    ],
  },
  { path: '/:pathMatch(.*)*', component: NotFound, meta: { title: '404 - 页面未找到' } },
];

// 路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

let lastCacheCheck = 0; // 上次检查时间的时间戳
const CACHE_CHECK_INTERVAL = 60 * 1000; // 缓冲时间（例如 1 分钟）

router.beforeEach(async (to, from, next) => {
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 更新面包屑
  const breadcrumbs = to.meta.breadcrumbs || [];
  store.commit('breadcrumbs/setBreadcrumbs', breadcrumbs);

  // 检查用户登录状态
  const userInfo = store.state.user.userInfo;
  const currentTime = Date.now();

  // 检查是否需要登录
  if (to.meta.requiresAuth) {
    if (!userInfo) {
      next({ path: '/' });
      return;
    }

    const { expirationTime = 0, rememberMe = false } = userInfo;

    if (currentTime > expirationTime && !rememberMe) {
      await store.dispatch('user/clearUserInfo');
      import('element-plus').then(({ ElMessage }) => {
        ElMessage.error("用户信息已过期，请重新登录。");
      });
      next({ path: '/' });
      return;
    }
  }

  // 如果是登录、注册或重置密码页面，则跳过配置缓存有效性检查
  if (['/', '/register', '/reset-password'].includes(to.path)) {
    next();  // 跳过配置缓存检查，直接执行路由导航
    return;
  }

  // 检查配置缓存有效性
  try {
    if (currentTime - lastCacheCheck > CACHE_CHECK_INTERVAL) {
      lastCacheCheck = currentTime;
      const isValid = await store.dispatch('dataCache/isConfigCacheValid');
      
      if (!isValid) {
        console.log('配置缓存无效，重新加载配置');
        await store.dispatch('dataCache/loadSystemConfig'); // 强制重新加载配置
      }
    }
  } catch (error) {
    console.error("Error validating config cache:", error);
  }

  next();  // 确保在数据加载完成后才执行路由导航
});


export default router;
