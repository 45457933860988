// router/customerRoutes.js
export default [
    {
      path: 'voucher',
      component: () => import('@/views/customer/voucher/VoucherSystem.vue'),
      meta: { title: '预存管理 - 神话网游小铺', breadcrumbs: [ '客户系统', '预存管理'], keepAlive: true }
    },
    {
      path: 'complex',
      component: () => import('@/views/customer/list/ComplexList.vue'),
      meta: { title: '综合查询 - 神话网游小铺', breadcrumbs: [ '客户系统', '综合查询'], keepAlive: true }
    },
    {
      path: 'complex/add',
      component: () => import('@/views/customer/operate/ComplexAdd.vue'),
      meta: { title: '综合新增 - 神话网游小铺', breadcrumbs: [ '客户系统', '综合新增'], keepAlive: true }
    },
    {
      path: 'complex/edit',
      component: () => import('@/views/customer/operate/ComplexEdit.vue'),
      meta: { title: '客户信息 - 神话网游小铺', breadcrumbs: [ '客户系统', '客户信息'], keepAlive: true }
    },
  ];
  