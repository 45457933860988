import axios from 'axios';

// 创建一个 axios 实例
const apiClient = axios.create({
  baseURL: '/api', // 配置基础路径，自动代理到后端
  timeout: 10000,  // 设置请求超时时间（单位：毫秒）
  headers: {
    'Content-Type': 'application/json'
  }
});

// 可以添加请求拦截器（可选）
apiClient.interceptors.request.use(
  config => {
    // 可以在此添加 token 等通用请求信息
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 可以添加响应拦截器（可选）
apiClient.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
);

export default apiClient;
