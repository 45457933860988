// router/settingsRoutes.js
export default [

    { 
      path: 'Profile',
      component: () => import('@/views/setting/ProfileSettings.vue'),
      meta: { title: '个人资料 - 神话网游小铺', breadcrumbs: ['个人资料']}
    },
    { 
      path: 'Parent',
      component: () => import('@/views/setting/ParentSetting'),
      meta: { title: '配置中心 - 神话网游小铺', breadcrumbs: ['配置中心']}
    }
  ];
  