
// router/orderRoutes.js
export default [
  {
    path: 'orders',
    component: () => import('@/views/order/Orders.vue'),
    meta: { title: '订单管理 - 神话网游小铺', breadcrumbs: ['订单管理'], keepAlive: true  }
  },
  {
    path: 'orders/edit/:id',  // 确保使用动态参数 id
    component: () => import('@/views/order/common/EditOrder.vue'),
    meta: { title: '编辑订单 - 神话网游小铺', breadcrumbs: [ '订单管理', '编辑订单'] }
  },
  {
    path: 'orders/parent',  // 新增订单和批量添加的父组件
    component: () => import('@/views/order/ParentAddOrder.vue'),
    meta: { title: '新增订单 - 神话网游小铺', breadcrumbs: ['新增订单'], keepAlive: true }
  }
];
