// src/store/index.js

import { createStore } from 'vuex';
import user from './modules/user';
import theme from './modules/uiSettings';
import breadcrumbs from './modules/breadcrumbs';
import dataCache from './modules/dataCache'; // 引入数据缓存模块

const store = createStore({
  modules: {
    user,         // 用户模块
    theme,        // 主题模块
    breadcrumbs,  // 面包屑模块
    dataCache,    // 数据缓存模块
  },
});


// 获取用户信息并初始化主题（假设 user 模块提供用户数据）
const userinfo = store.state.user.userinfo; // 确保 user 模块中有 userinfo 数据
if (userinfo) {
  store.dispatch('theme/initializeTheme', userinfo);
}


export default store;
