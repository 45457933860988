import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import * as Icons from '@element-plus/icons-vue'; // 引入 Element Plus 图标
import App from './App.vue';
import router from './router';
import store from './store';
import zhCn from 'element-plus/es/locale/lang/zh-cn';


import '@/assets/styles/index.css'; // 引入全局样式

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'element-plus/dist/index.css'; // 引入 Element Plus 样式
import 'element-plus/theme-chalk/dark/css-vars.css'; // 引入暗黑模式的 CSS 变量

import axiosInstance from './axios'; // 引入刚创建的 Axios 实例

const app = createApp(App);

// 注册 Element Plus 图标
for (const [key, component] of Object.entries(Icons)) {
  app.component(key, component);
}
// 将 axios 挂载到全局
app.config.globalProperties.$axios = axiosInstance;

app.use(router);
app.use(store);
app.use(ElementPlus, { locale: zhCn });
app.mount('#app');
